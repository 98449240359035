<template>
  <div class="component container">
    <div class="row">
      <h3>{{ this.city }}</h3>
    </div>

    <div v-if="forecastData === null" class="loading">
      Chargement...
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-7">
          <div class="summary">
            <p><i :class="weatherIcon" class="weather-icon"></i></p>
            <p>📆</p>
          </div>
          <div class="weather-details">
            <div class="detail-row">
              <i class="wi wi-thermometer"></i>
              <span>{{ Math.min(...this.forecastData.minutely_15.temperature_2m) }}° ➡️ {{ Math.max(...this.forecastData.minutely_15.temperature_2m) }}°</span>
            </div>
            <div class="detail-row">
              <i class="wi wi-rain"></i>
              <span>{{ this.totalPrecipitation }} mm ({{ Math.max(...this.forecastData.minutely_15.precipitation_probability) }}%)</span>
            </div>
            <div class="detail-row">
              <i class="wi wi-strong-wind"></i>
              <span>{{ Math.max(...this.forecastData.minutely_15.wind_speed_10m) }} km/h ({{ this.windDirection }})</span>
            </div>
            <div class="detail-row">
              <i class="wi wi-fog"></i>
              <span>{{ this.visibilityPercentage }} %</span>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="summary">
            <p><i :class="currentWeatherIcon" class="weather-icon"></i></p>
            <p>⌚</p>
          </div>

          <div class="weather-details">
            <div class="detail-row">
              <span>{{ this.currentTemperature }}°</span>
            </div>
            <div class="detail-row">
              <span>{{ this.currentPrecipitation }} mm ({{ currentPrecipitationProbability }}%)</span>
            </div>
            <div class="detail-row">
              <span>{{ currentWindSpeed }} km/h ({{ currentWindDirection }})</span>
            </div>
            <div class="detail-row">
              <span>{{ this.currentVisibilityPercentage }} %</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeatherComponent',
  props: {
    city: String,
    latitude: Number,
    longitude: Number
  },
  created() {
    this.getForecast()
  },
  data() {
    return {
      forecastData: null,
      currentIndex: (Math.floor(Date.now() / 1000 / 60 / 15) % 96) + (2*4)
    }
  },
  methods: {
    getForecast() {
      fetch(this.forecastUrl, {
        method: 'GET'
      })
          .then(response => response.json())
          .then(forecastJson => {
            this.forecastData = forecastJson
          })
    },

    getWeatherIcon() {
      const maxPrecipitationProbability = Math.max(...this.forecastData.minutely_15.precipitation_probability);
      const maxWindSpeed = Math.max(...this.forecastData.minutely_15.wind_speed_10m);
      const maxTemperature = Math.max(...this.forecastData.minutely_15.temperature_2m);

      if (maxPrecipitationProbability > 50) {
        return 'wi wi-rain';
      } else if (maxWindSpeed > 15) {
        return 'wi wi-strong-wind';
      } else if (maxTemperature > 30) {
        return 'wi wi-hot';
      } else if (maxTemperature < 0) {
        return 'wi wi-snowflake-cold';
      } else {
        return 'wi wi-day-sunny';
      }
    }
  },
  computed: {
    forecastUrl() {
      return `https://api.open-meteo.com/v1/forecast?latitude=${this.latitude}&longitude=${this.longitude}&minutely_15=temperature_2m,apparent_temperature,precipitation_probability,precipitation,visibility,wind_speed_10m,wind_direction_10m&timezone=Europe%2FBerlin&forecast_days=1`
    },
    totalPrecipitation() {
      return this.forecastData.minutely_15.precipitation.reduce((a, b) => a + b, 0).toFixed(2);
    },
    windDirection() {
      const degrees = this.forecastData.minutely_15.wind_direction_10m[0];
      const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

      return directions[Math.round(degrees / 45) % 8];
    },
    visibilityPercentage() {
      const maxVisibility = 24140; // Maximum visibility in meters
      const minVisibility = Math.min(...this.forecastData.minutely_15.visibility);

      return ((minVisibility / maxVisibility) * 100).toFixed(2);
    },
    weatherIcon() {
      if (this.forecastData) {
        return this.getWeatherIcon();
      }
      return 'wi wi-na';
    },
    currentTemperature() {
      return this.forecastData.minutely_15.temperature_2m[this.currentIndex];
    },
    currentPrecipitation() {
      return this.forecastData.minutely_15.precipitation[this.currentIndex].toFixed(2);
    },
    currentPrecipitationProbability() {
      return this.forecastData.minutely_15.precipitation_probability[this.currentIndex];
    },
    currentWindSpeed() {
      return this.forecastData.minutely_15.wind_speed_10m[this.currentIndex];
    },
    currentWindDirection() {
      const degrees = this.forecastData.minutely_15.wind_direction_10m[this.currentIndex];
      const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

      return directions[Math.round(degrees / 45) % 8];
    },
    currentVisibilityPercentage() {
      const maxVisibility = 24140; // Maximum visibility in meters
      const visibility = this.forecastData.minutely_15.visibility[this.currentIndex];

      return ((visibility / maxVisibility) * 100).toFixed(2);
    },
    currentWeatherIcon() {
      if (this.forecastData) {
        const precipitationProbability = this.forecastData.minutely_15.precipitation_probability[this.currentIndex];
        const windSpeed = this.forecastData.minutely_15.wind_speed_10m[this.currentIndex];
        const temperature = this.forecastData.minutely_15.temperature_2m[this.currentIndex];

        if (precipitationProbability > 50) {
          return 'wi wi-rain';
        } else if (windSpeed > 15) {
          return 'wi wi-strong-wind';
        } else if (temperature > 30) {
          return 'wi wi-hot';
        } else if (temperature < 0) {
          return 'wi wi-snowflake-cold';
        } else {
          return 'wi wi-day-sunny';
        }
      }
      return 'wi wi-na';
    }
  }
}
</script>

<style scoped>
@import 'weather-icons/css/weather-icons.min.css';

.loading {
  text-align: center;
  font-size: 1.2em;
  color: #888;
}

.summary {
  text-align: center;
  margin-bottom: 10px;
}

.weather-icon {
  font-size: 48px;
  color: #ff9800;
}

.weather-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-row {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
}

.detail-row i {
  font-size: 20px;
  color: #0277bd;
}
</style>
