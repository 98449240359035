<template>
  <div class="news-component component custom-height">
    <div class="row">
      <div class="col-12">
        <h3>News ({{ this.news ? this.news.length : 0 }})</h3>
      </div>
    </div>

    <div class="row custom-height row-padding-news"> <!-- TODO: Padding -->
      <div class="col-12 custom-height">
        <div v-if="news === null">
          <div>Chargement...</div>
        </div>

        <template v-else>
          <div v-if="!news.length">
            <div>Rien de neuf pour le moment.</div>
          </div>

          <div v-else class="scrollbars custom-height">
            <NewsItemComponent :id="item.id" :title="item.title" :link="item.url" :category="item.feed.title"
                               v-for="(item, index) in news" v-bind:key="index"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItemComponent from "@/components/NewsItemComponent.vue";

export default {
  name: 'NewsComponent',
  components: {NewsItemComponent},
  data() {
    return {
      news: null
    }
  },
  created() {
    this.getNews()
  },
  methods: {
    getNews() {
      fetch('https://rss.aurele.ch/v1/entries?status=unread&order=published_at&direction=desc', {
        method: 'GET',
        headers: {
          'X-Auth-Token': this.getRssApiKey()
        }
      })
        .then(response => response.json())
        .then(json => Object.values(json.entries))
        .then(unread => {
          this.news = unread
        })
    }
  }
}
</script>

<style lang="scss">
.news-component {
  .row-padding-news {
    padding-bottom: 41.59px;
  }

  .scrollbars {
    overflow: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      //background-color: transparent;
      background-color: rgba(211, 211, 211, 0.2);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 100px;
    }
  }
}
</style>