<template>
  <div class="component transport-component">
    <div class="row">
      <div class="col-12">
        <h3>{{ this.visibleFrom || this.from }} <span class="arrow">→</span> {{ this.visibleTo || this.to }}
        </h3>
      </div>
    </div>

    <div v-if="this.response === null">
      <div class="row">
        <div class="col">
          Chargement...
        </div>
      </div>
    </div>

    <template v-else>
      <div class="row" v-for="(connection, index) in this.response.connections" v-bind:key="index">
        <div class="col">
          <div>
            {{ connection.departure.substring(11, 16) }}
            <span class="arrow">→</span>
            {{ connection.arrival.substring(11, 16) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TransportComponent",
  props: {
    from: String,
    to: String,
    num: Number,
    visibleFrom: {
      type: String,
      required: false
    },
    visibleTo: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      response: null
    }
  },
  created() {
    this.fetchTimetable()
  },
  methods: {
    async fetchTimetable() {
      let url = new URL('https://timetable.search.ch/api/route.fr.json')
      let params = {
        "from": this.from,
        "to": this.to,
        "num": this.num,
        "show_delays": 1,
        "show_trackchanges": 1,
      }
      for (let p in params)
        url.searchParams.append(p, params[p])

      return await fetch(url, {method: 'GET'})
          .then(response => response.json())
          .then(json => this.response = json)
    }
  }
}
</script>

<style lang="scss">
.transport-component {
  .arrow {
    color: rgba(211, 211, 211, 0.5);
  }
}
</style>