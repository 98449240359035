import {createApp} from 'vue'
import App from './App.vue'

createApp(App)
    .mixin({
        methods: {
            getApiUrl: () => {
                return window.hConfig.BASE_URL + "/api/v1";
            },
            getRssApiKey: () => {
                return window.hConfig.RSS_API_KEY;
            }
        }
    })
    .mount('#app')

