<template>
  <div class="container-xl custom-height">
    <div class="row custom-height">
      <div class="col-md-12 col-lg-9">
        <div class="row">
          <div class="col-12">
            <WelcomeComponent :photoData=this.backgroundData />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <WeatherComponent city="Sion" latitude="46.2274" longitude="7.3556"/>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">
            <WeatherComponent city="Crans-Montana" latitude="46.3132" longitude="7.4791"/>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">
            <WeatherComponent city="Le Locle" latitude="47.0562" longitude="6.7491"/>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <TransportComponent from="Sion" to="Le Locle" :num="3"/>
          </div>

          <div class="col-sm-12 col-lg-6">
            <TransportComponent from="Le Locle" to="Sion" :num="3"/>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-3 custom-height">
        <div class="row custom-height">
          <div class="col-12 custom-height">
            <NewsComponent/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './main'
import WelcomeComponent from './components/WelcomeComponent.vue'
import WeatherComponent from './components/WeatherComponent.vue'
import NewsComponent from './components/NewsComponent.vue'
import TransportComponent from "./components/TransportComponent.vue";

export default {
  name: 'App',
  components: {
    TransportComponent,
    WelcomeComponent,
    WeatherComponent,
    NewsComponent
  },
  data() {
    return {
      backgroundData: null
    }
  },
  created() {
    this.defineBackground()
  },
  methods: {
    defineBackground() {
      const isMobile = window.innerWidth <= 600
      let endpoint = 'photoDesktop'

      if (isMobile) {
        endpoint = 'photoMobile'
      }

      fetch(`${this.getApiUrl()}/${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then(data => {
            this.backgroundData = data
            this.$nextTick(() => {
              document.body.style.backgroundImage = `url(${this.backgroundData.url})`
              document.body.style.backgroundSize = `cover`
              document.body.style.backgroundRepeat = `no-repeat`
              document.body.style.backgroundPosition = `center center`
              document.body.style.backgroundAttachment = `fixed`
            })
          })
          .catch(error => {
            console.error('Error:', error)
          })
    }
  }
}
</script>

<style lang="scss">
body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100vh;
  font-family: Helvetica, serif;

  #app {
    height: 100%;

    .component {
      margin-bottom: 30px;

      padding: 15px;

      backdrop-filter: blur(2px);
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.35);
      color: #fff;
    }

    .custom-height {
      height: 100% !important;
    }

    @media (max-width: 991.98px) {
      .custom-height {
        max-height: 50vh;
      }
    }
  }
}
</style>
