<template>
  <div class="component">
    <div class="row">
      <div class="col welcome">
        <h1>{{ welcome }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col timeanddate">
        <div>Il est <strong>{{ time }}</strong> le <strong>{{ date }}</strong>.</div>
      </div>
    </div>

    <div v-if="this.photoData !== null">
      <div class="row">
        <div class="col timeanddate">
          📸 {{ photoData.location }} par <a :href="this.photoData.author_profile" target="_blank">{{ this.photoData.author }}</a> sur <a href="https://unsplash.com/?utm_source=h.aurele.ch&utm_medium=referral" target="_blank">Unsplash</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeComponent',
  props: {
    photoData: Object
  },
  data() {
    return {
      welcome: 'Bienvenue, Aurèle',
      time: '',
      date: '',
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    }
  },
  mounted() {
    this.updateTimeAndDate()
    setInterval(this.updateTimeAndDate, 1000)
  },
  methods: {
    updateTimeAndDate() {
      const d = new Date()
      this.time = this.prependZeros(d.getHours()) + ':' + this.prependZeros(d.getMinutes()) + ':' + this.prependZeros(d.getSeconds())
      this.date = this.days[d.getDay()] + ' ' + this.prependZeros(d.getDate()) + ' ' + this.months[d.getMonth()] + ' ' + d.getFullYear()
    },
    prependZeros(val) {
      return ("0" + val).slice(-2)
    }
  }
}
</script>

<style>
.welcome {
  text-align: center;
}

.timeanddate {
  text-align: center;
  color: #ffffff8f;
}
</style>