<template>
  <div class="newsItem">
    <span class="remove" @click="removeNews">✖</span> <a :href="link" target="_blank"> {{ this.title }}</a>
  </div>
</template>

<script>
export default {
  name: 'NewsItemComponent',
  props: {
    id: Number,
    title: String,
    link: String,
    category: String
  },
  methods: {
    removeNews() {
      fetch('https://rss.aurele.ch/v1/entries', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': this.getRssApiKey()
        },
        body: JSON.stringify({
          entry_ids: [this.id],
          status: 'read'
        })
      })
          .then(response => {
            if (response.status === 204) {
              let updateNewsList = this.$parent.news.filter((el) => el.id !== this.id)
              this.$parent.news = updateNewsList
            }
          })
    }
  }
}
</script>

<style lang="scss">
.newsItem {
  a {
    text-decoration: none;
    color: white;
    position: relative;
  }

  a:hover {
    color: #364156
  }

  .remove {
    color: lightgray;
    font-size: smaller;
    cursor: pointer;
  }
}
</style>